import React from 'react';
import moment from 'moment';
import { Spin, Row, Col, Radio, message, DatePicker } from 'antd';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { getTranslationCache } from '../../selectors/language';
import { getCurrentTimezone, getUserProfile } from '../../selectors/layout';
import Line from './Widgets/Line';
import Table from './Widgets/Table';
import './CSS/trends.css';
import { StyledMain } from './CSS/styles';
import NoRecordComponent from './Utils/NoRecords';
import FilterComponent from './FilterComponent';
import { getTrendData } from './ChartCalls';
const { RangePicker } = DatePicker;

const color = [
  'orange',
  'tomato',
  'steelblue',
  'green',
  'pink',
  'voilet',
  'yellow',
  'red',
  'blue',
  'gray'
];

class Trends extends React.Component {
  timeTicket = null;
  requestCheck = true;

  constructor(props) {
    super(props);
    this.state = {
      trendData: [],
      tunedData: {},

      loading: false,
      currentSelectionTab: 'day',
      GraphType: 'line',
      ViewType: 'Single',
      userProfile: props.userProfile,
      translationCache: props.translationCache || [],

      Type: 'live',
      // selectedDevice: [],
      selectedParameter: [],
      selectedParameterType: [],
      selectedInterval: '',
      selectedFromDate_ToDate: [],
      selectedFromDate_ToDate1: [],
      selectedDateRangeComparision: []
    };
  }
  formHeader = React.createRef();
  abortController = new window.AbortController();

  componentDidMount() {
    if (this.props.tunedData) {
      this.OnGenerateTrendData(this.props.tunedData);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.translationCache !== this.props.translationCache ||
      prevProps.userProfile !== this.props.userProfile
    ) {
      this.setState({
        translationCache: this.props.translationCache,
        userProfile: this.props.userProfile
      });
    }
    if (this.props.tunedData !== prevProps.tunedData) {
      this.OnGenerateTrendData(this.props.tunedData, {}, 'ViewTrends');
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  OnGenerateTrendData = async (tunedData, filterObject, source) => {
    if (tunedData && Object.keys(tunedData).length > 0) {
      if (this.timeTicket) {
        clearInterval(this.timeTicket);
      }
      if (this.requestCheck) {
        this.requestCheck = false;
        this.getTrendDataAPI(tunedData, filterObject, source);
      }
      // if (tunedData.Type === 'live') {
      //   this.timeTicket = setInterval(() => {
      //     if (this.requestCheck) {
      //       this.requestCheck = false;
      //       this.getTrendDataAPI(tunedData, filterObject, source);
      //     }
      //   }, 30000);
      // }
    } else {
      this.setState({
        trendData: [],
        tunedData: {},

        loading: false,
        currentSelectionTab: 'day',
        GraphType: 'line',
        ViewType: 'Single',
        Type: 'live',
        // selectedDevice: [],
        selectedParameter: [],
        selectedParameterType: [],
        selectedInterval: '',
        selectedFromDate_ToDate: [],
        selectedFromDate_ToDate1: [],
        selectedDateRangeComparision: []
      });
    }
  };

  getTrendDataAPI = async (tunedData, filterObject, source) => {
    this.setState({ trendData: {} });
    let trendData = await getTrendData(tunedData, this.abortController);
    // let GraphType = this.state.selectedParameterType === 'Status' ? 'Table' : 'line';
    this.setState({
      trendData,
      selectedFromDate_ToDate1: [
        moment.utc(tunedData.FromDate, 'YYYY-MM-DD HH:mm:ss').local(),
        moment.utc(tunedData.ToDate, 'YYYY-MM-DD HH:mm:ss').local()
      ],
      selectedFromDate_ToDate: [
        moment.utc(tunedData.FromDate1, 'YYYY-MM-DD HH:mm:ss').local(),
        moment.utc(tunedData.ToDate1, 'YYYY-MM-DD HH:mm:ss').local()
      ],
      selectedDateRangeComparision: [
        moment.utc(tunedData.FromDate2, 'YYYY-MM-DD HH:mm:ss').local(),
        moment.utc(tunedData.ToDate2, 'YYYY-MM-DD HH:mm:ss').local()
      ]
    });

    if (this.state.ViewType && this.state.ViewType === 'Single') {
      if (trendData && Array.isArray(trendData)) {
        this.setState(
          {
            ...filterObject,
            trendData,
            loading: false,
            payLoad: tunedData,
            update: true,
            GraphType: tunedData.GraphType,
            tunedData,
            source,
            filterObject
          },
          () => {
            this.requestCheck = true;
          }
        );
      } else {
        this.requestCheck = true;
        this.setState({ loading: false, source, trendData: [] });
        message.warn('Data is not available for selected parameters');
      }
    } else {
      if (trendData && trendData.length > 0) {
        if (trendData.length > 0) {
          this.setState(
            {
              ...filterObject,
              trendData,
              tunedData,
              update: true,
              source,
              filterObject
            },
            () => {
              this.requestCheck = true;
            }
          );
        } else {
          this.setState(
            {
              ...filterObject,
              trendData: [...this.state.trendData, ...trendData],
              loading: false,
              payLoad: tunedData,
              update: true,
              // GraphType,
              tunedData,
              source,
              filterObject
            },
            () => {
              this.requestCheck = true;
            }
          );
        }
      } else {
        this.requestCheck = true;
        this.setState({ loading: false, source, trendData: [] });
        message.warn('Data is not available for selected parameters');
      }
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  onSelectionTabChange = (e) => {
    let { tunedData } = this.state;
    this.setState({
      currentSelectionTab: e.target.value
    });
    this.OnGenerateTrendData({ ...tunedData, SelectionType: e.target.value });
  };

  disabledDate = (current) => {
    return current > moment().startOf('day').add(1, 'day');
  };

  // for range comparision

  onChangeFromDate_ToDate = (value, dateString) => {
    let { tunedData, filterObject, source } = this.state;
    let selectedFromDate_ToDate = [
      moment(dateString[0]).format('YYYY-MM-DD HH:mm:ss'),
      moment(dateString[1]).format('YYYY-MM-DD HH:mm:ss')
    ];
    let updatedTunedData = {
      ...tunedData,
      FromDate1: moment(selectedFromDate_ToDate[0]).utc().format('YYYY-MM-DD HH:mm:ss'), //conversion to utc
      ToDate1: moment(selectedFromDate_ToDate[1]).utc().format('YYYY-MM-DD HH:mm:ss')
    };
    let updatedFilterObject = {
      ...filterObject,
      selectedFromDate_ToDate: [value[0], value[1]]
    };
    this.OnGenerateTrendData(updatedTunedData, updatedFilterObject, source);
  };

  onChangeFromDate1_ToDate1 = (value, dateString) => {
    let { tunedData, filterObject, source } = this.state;
    let selectedDateRangeComparision = [
      moment(dateString[0]).format('YYYY-MM-DD HH:mm:ss'),
      moment(dateString[1]).format('YYYY-MM-DD HH:mm:ss')
    ];
    let updatedTunedData = {
      ...tunedData,
      FromDate2: moment(selectedDateRangeComparision[0]).utc().format('YYYY-MM-DD HH:mm:ss'), //conversion to utc
      ToDate2: moment(selectedDateRangeComparision[1]).utc().format('YYYY-MM-DD HH:mm:ss')
    };
    let updatedFilterObject = {
      ...filterObject,
      selectedDateRangeComparision: [value[0], value[1]]
    };
    this.OnGenerateTrendData(updatedTunedData, updatedFilterObject, source);
  };

  // for time with selection type
  onChangeFromDate_ToDate1 = (value, dateString) => {
    let { tunedData, filterObject, source } = this.state;
    let selectedFromDate_ToDate1 = [
      moment(dateString[0]).format('YYYY-MM-DD HH:mm:ss'),
      moment(dateString[1]).format('YYYY-MM-DD HH:mm:ss')
    ];
    let updatedTunedData = {
      ...tunedData,
      FromDate: moment(selectedFromDate_ToDate1[0]).utc().format('YYYY-MM-DD HH:mm:ss'), //conversion to utc
      ToDate: moment(selectedFromDate_ToDate1[1]).utc().format('YYYY-MM-DD HH:mm:ss')
    };
    let updatedFilterObject = {
      ...filterObject,
      selectedFromDate_ToDate1: [value[0], value[1]]
    };
    this.OnGenerateTrendData(updatedTunedData, updatedFilterObject, source);
  };

  render() {
    const { tunedData, trendData, GraphType, source, filterObject } = this.state;
    let { Type, loading, ViewType } = tunedData;
    return (
      <StyledMain style={{ minHeight: window.innerHeight - 73 }}>
        <Row>
          <Col
            span={source && source === 'ViewTrends' ? 24 : 16}
            push={source && source === 'ViewTrends' ? 0 : 8}
          >
            <div
              style={{
                marginLeft: '24px',
                // marginRight: '24px',
                marginTop: '24px',
                background: 'white',
                minHeight: window.innerHeight
              }}
            >
              {tunedData && Object.keys(tunedData).length > 0 ? (
                <div
                  style={{
                    height: '55vh'
                  }}
                >
                  {loading === true ? (
                    <div
                      style={{
                        textAlign: 'center',
                        color: 'white'
                      }}
                    >
                      <h2 style={{ color: 'white' }}>
                        <Spin spinning={loading} tip="Loading..." size="large"></Spin>
                      </h2>
                    </div>
                  ) : trendData && Array.isArray(trendData) ? (
                    <React.Fragment>
                      <div
                        style={{ display: 'flex', flexDirection: 'row-reverse', padding: '20px' }}
                      >
                        {this.state.selectedParameterType !== 'Status' ? (
                          <Radio.Group
                            onChange={(e) => this.setState({ GraphType: e.target.value })}
                            value={GraphType}
                          >
                            <Radio.Button value="line">{this.translation('Line')}</Radio.Button>
                            <Radio.Button value="bar">{this.translation('Bar')}</Radio.Button>
                            <Radio.Button value="Area">{this.translation('Area')}</Radio.Button>
                            <Radio.Button value="Table">{this.translation('Table')}</Radio.Button>
                          </Radio.Group>
                        ) : null}
                      </div>

                      {Type === 'historic' ? (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row-reverse',
                              paddingBottom: '0px 20px'
                            }}
                          >
                            <RangePicker
                              showTime={{ format: 'HH:mm:ss' }}
                              format="YYYY-MM-DD HH:mm:ss"
                              placeholder={[
                                this.translation('Start DateTime'),
                                this.translation('End DateTime')
                              ]}
                              value={this.state.selectedFromDate_ToDate1}
                              onChange={this.onChangeFromDate_ToDate1}
                              onOk={this.onOk}
                              disabledDate={this.disabledDate}
                            />
                          </div>
                        </>
                      ) : null}
                      {Type === 'timeRange' ? (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row-reverse',
                              paddingBottom: '0px 20px'
                            }}
                          >
                            <RangePicker
                              showTime={{ format: 'HH:mm:ss' }}
                              format="YYYY-MM-DD HH:mm:ss"
                              placeholder={[
                                this.translation('Start DateTime'),
                                this.translation('End DateTime')
                              ]}
                              value={this.state.selectedDateRangeComparision}
                              onChange={this.onChangeFromDate1_ToDate1}
                              onOk={this.onOk}
                              disabledDate={this.disabledDate}
                            />
                            <RangePicker
                              showTime={{ format: 'HH:mm:ss' }}
                              format="YYYY-MM-DD HH:mm:ss"
                              placeholder={[
                                this.translation('Start DateTime'),
                                this.translation('End DateTime')
                              ]}
                              value={this.state.selectedFromDate_ToDate}
                              onChange={this.onChangeFromDate_ToDate}
                              onOk={this.onOk}
                              disabledDate={this.disabledDate}
                            />
                          </div>
                        </>
                      ) : null}
                      {Type === 'timeWithSelectionType' ? (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row-reverse',
                              paddingBottom: '0px 20px'
                            }}
                          >
                            <RangePicker
                              showTime={{ format: 'HH:mm:ss' }}
                              format="YYYY-MM-DD HH:mm:ss"
                              placeholder={[
                                this.translation('Start DateTime'),
                                this.translation('End DateTime')
                              ]}
                              value={this.state.selectedFromDate_ToDate1}
                              onChange={this.onChangeFromDate_ToDate1}
                              onOk={this.onOk}
                              disabledDate={this.disabledDate}
                            />
                          </div>
                        </>
                      ) : null}
                      <div
                        style={{ display: 'flex', flexDirection: 'row-reverse', padding: '20px' }}
                      >
                        {Type === 'timeWithSelectionType' ? (
                          <Radio.Group
                            defaultValue={this.state.currentSelectionTab}
                            onChange={this.onSelectionTabChange}
                          >
                            <Radio.Button value="day">{this.translation('Daily')}</Radio.Button>
                            <Radio.Button value="week">{this.translation('Week')}</Radio.Button>
                            <Radio.Button value="month">{this.translation('Month')}</Radio.Button>
                            <Radio.Button value="year">{this.translation('Year')}</Radio.Button>
                          </Radio.Group>
                        ) : (
                          ''
                        )}
                      </div>
                      {
                        <>
                          {ViewType && ViewType === 'Single' ? (
                            <>
                              {GraphType !== 'Table' ? (
                                <Line
                                  graphType={GraphType}
                                  panelData={{
                                    graph: 'trends',
                                    trendData: trendData,
                                    colors: color,
                                    linetype: '',
                                    update: this.state.update,
                                    Type,
                                    tunedData,
                                    parameterType:
                                      filterObject && filterObject.selectedParameterType
                                  }}
                                  currentTimeZone={this.props.currentTimeZone}
                                />
                              ) : (
                                <div style={{ height: '55vh' }}>
                                  <Table
                                    panelData={{
                                      graph: 'trends',
                                      trendData: trendData,
                                      colors: color,
                                      linetype: '',
                                      update: this.state.update,
                                      Type,
                                      tunedData
                                    }}
                                    currentTimeZone={this.props.currentTimeZone}
                                    className="trendTable"
                                    dataSource={trendData}
                                    translationCache={this.props.translationCache}
                                  />
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {GraphType !== 'Table' ? (
                                <span
                                  style={{
                                    overflowY: 'auto',
                                    height: window.innerHeight - 215
                                  }}
                                >
                                  {trendData &&
                                    Array.isArray(trendData) &&
                                    trendData.map((graphData, i) => {
                                      return (
                                        <div
                                          key={i}
                                          style={{
                                            height: '48vh',
                                            marginTop: '1%'
                                          }}
                                        >
                                          {graphData &&
                                          Array.isArray(graphData) &&
                                          graphData.length > 0 ? (
                                            <Line
                                              graphType={GraphType}
                                              panelData={{
                                                graph: 'trends',
                                                trendData: graphData,
                                                tunedData,
                                                colors: [color[i]],
                                                linetype: '',
                                                update: this.state.update,
                                                Type,
                                                trendIndex: i,
                                                parameterType:
                                                  filterObject && filterObject.selectedParameterType
                                              }}
                                              viewType={ViewType}
                                              currentTimeZone={this.props.currentTimeZone}
                                            />
                                          ) : (
                                            <div
                                              style={{
                                                width: '100%',
                                                height: '48vh',
                                                backgroundColor: 'white'
                                              }}
                                            >
                                              <NoRecordComponent />
                                            </div>
                                          )}
                                        </div>
                                      );
                                    })}
                                </span>
                              ) : (
                                <span
                                  style={{
                                    overflowY: 'auto',
                                    height: window.innerHeight - 215
                                  }}
                                >
                                  {trendData &&
                                    Array.isArray(trendData) &&
                                    trendData.map((trend, index) => {
                                      return (
                                        <div
                                          key={index}
                                          style={{
                                            marginTop: '40px'
                                          }}
                                        >
                                          <Table
                                            panelData={{
                                              graph: 'trends',
                                              trendData: trend,
                                              colors: color,
                                              update: this.state.update,
                                              Type,
                                              tunedData,
                                              trendIndex: index
                                            }}
                                            currentTimeZone={this.props.currentTimeZone}
                                            className="trendTable"
                                            dataSource={trendData}
                                            viewType={ViewType}
                                          />
                                        </div>
                                      );
                                    })}
                                </span>
                              )}
                            </>
                          )}
                        </>
                      }
                    </React.Fragment>
                  ) : (
                    <h2 style={{ textAlign: 'center' }}>
                      {this.translation('Configure To View Trends')}
                    </h2>
                  )}
                </div>
              ) : (
                <h2 style={{ textAlign: 'center' }}>
                  {this.translation('Configure To View Trends')}
                </h2>
              )}
            </div>
          </Col>
          {source && source === 'ViewTrends' ? null : (
            <Col span={8} pull={16}>
              <div
                style={{
                  margin: '24px 0px 0px 24px',
                  padding: '20px',
                  color: 'black',
                  background: 'white',
                  minHeight: window.innerHeight - 73
                }}
              >
                <FilterComponent
                  {...this.props}
                  OnGenerateTrendData={this.OnGenerateTrendData}
                  GraphType={this.state.GraphType}
                />
              </div>
            </Col>
          )}
        </Row>
      </StyledMain>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});
export default connect(mapStateToProps)(Trends);
