import React from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';
import { LeftOutlined } from '@ant-design/icons';
import { createStructuredSelector } from 'reselect';
import { Input, Row, Col, message, Button } from 'antd';

import history from '../../commons/history';
import {
  saveRule,
  ruleListID,
  editRule,
  // urlList,
  deviceList,
  getparameterChangeList,
  // getDeviceData,
  // recipientListAPI,
  getparameterList,
  getparameterListByDeviceType,
  // nodeviceParameterList,
  // tablesList,
  getColumnsList,
  priorityList,
  getHierarchyLevels,
  deviceTypeListData
  // getUserHierarcy
  // getParamtersListWithType
} from './Apicalls';
import { DeviceRuleView, DeviceTypeRule } from './Tabs';
import {
  defaultState,
  deviceTypedefaultState,
  predictionDefaultState,
  noDeviceDefault,
  tableState
  // recipientState
} from './utils/const';
import localStorage from '../../utils/localStorage';
import scriptGenerator, { replicateRule } from './scriptGenerator';
import { getUserProfile } from '../../selectors/layout';
import { BlockedLabel, StyledDashboard, Tabstyled } from './style';
import { getTranslationCache } from '../../selectors/language';
import constants from './constants';

const { TabPane } = Tabstyled;

class RuleEngine extends React.Component {
  constructor(props) {
    super(props);
    let objDefault = cloneDeep(defaultState);
    this.state = {
      ...objDefault,
      userProfile: props.userProfile || {},
      enable: false,
      // disabledParamTypeDevice: false,
      onChange: false,
      treeData: [],
      edit: false
    };
  }

  async componentDidMount() {
    if (this.props.match.params.id) {
      try {
        let data = await ruleListID(this.props.match.params.id);

        data.data[0].Payload.edit = true;
        this.setState({
          ...data.data[0].Payload,
          replicateDevices: data.data[0].Payload.replicateDevices,
          selectedEquipment: data.data[0].Payload.selectedDevices
        });
      } catch (error) {
        console.log(error);
      }
      this.setState({
        edit: true
      });
    } else {
      this.handleParameterSelect('Operational', 0, 'inputList');
    }
    let parameterChangeList = await getparameterChangeList();

    let data = await deviceList();
    let deviceTypeList = await deviceTypeListData();
    let deviceListData =
      data &&
      data.data &&
      Object.keys(data.data).map((devicekey) => {
        return data.data[devicekey];
      });
    this.setState({
      deviceList: deviceListData ? deviceListData : [],
      deviceTypeList,
      parameterChangeList: parameterChangeList ? parameterChangeList : []
    });
    defaultState.deviceList = deviceListData;
    tableState.deviceList = deviceListData;
    deviceTypedefaultState.deviceTypeList = deviceTypeList;
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.userProfile !== this.props.userProfile) {
      this.setState({
        userProfile: this.props.userProfile
      });
    }
  };

  handleInputChange = (e, index, type, mode) => {
    if (type === 'select') {
      const list = [...this.state[mode]];
      list[index]['operater'] = e;
      this.setState({ [mode]: list });
    } else {
      const { name, value } = e.target;
      const list = [...this.state[mode]];
      list[index][name] = value;
      this.setState({ [mode]: list });
    }
  };

  handleTextAreaChange = (e, index, mode, dignosis) => {
    let list = [...this.state[mode]];
    list[index][dignosis] = e.target.value;
    this.setState({ [mode]: list });
  };

  handleRemoveClick = (index, mode) => {
    const list = [...this.state[mode]];
    if (index === list.length - 1) {
      let currentIndex = list.length - 2;
      let prevIndex = list.length - 1;
      list[currentIndex]['action'] = list[prevIndex]['action'];
      list[currentIndex]['delay'] = list[prevIndex]['delay'];
      list[currentIndex]['frequency'] = list[prevIndex]['frequency'];
      list[currentIndex]['preventive'] = list[prevIndex]['preventive'];
      list[currentIndex]['rootcause'] = list[prevIndex]['rootcause'];
      list[currentIndex]['recommandation'] = list[prevIndex]['recommandation'];
      list.splice(index, 1);
    } else {
      list.splice(index, 1);
    }
    this.setState({ [mode]: list });
  };

  handleAddClick = (mode) => {
    let typeList = [...this.state[mode]];
    let index = typeList.length - 1;
    if (this.state.ruleType === 'realTime') {
      let newItem = {
        parameterName: typeList[index]['parameterName'],
        device: typeList[index]['device'],
        tagName: '',
        operater: '',
        value: '',
        HierarchyId: typeList[index]['HierarchyId'],
        action: typeList[index]['action'],
        delay: typeList[index]['delay'],
        frequency: typeList[index]['frequency'],
        preventive: typeList[index]['preventive'],
        rootcause: typeList[index]['rootcause'],
        recommandation: typeList[index]['recommandation'],
        54765: ''
      };
      delete typeList[index]['action'];
      delete typeList[index]['delay'];
      delete typeList[index]['frequency'];
      delete typeList[index]['preventive'];
      delete typeList[index]['rootcause'];
      delete typeList[index]['recommandation'];
      this.setState({
        [mode]: [...typeList, newItem],
        enable: false
        // disabledParamTypeDevice: true
      });
    } else {
      let newItem = {
        tagName: '',
        operater: '',
        value: '',
        action: typeList[index]['action'],
        delay: typeList[index]['delay'],
        frequency: typeList[index]['frequency'],
        preventive: typeList[index]['preventive'],
        rootcause: typeList[index]['rootcause'],
        recommandation: typeList[index]['recommandation'],
        54765: ''
      };

      delete typeList[index]['action'];
      delete typeList[index]['delay'];
      delete typeList[index]['frequency'];
      delete typeList[index]['preventive'];
      delete typeList[index]['rootcause'];

      this.setState({ [mode]: [...typeList, newItem] });
    }
    delete typeList[index]['recommandation'];

    // if (this.state.ruleType === 'realTime') {
    //   if (typeList[0].device === '') {
    //     message.error('Please Select Equipment to Add New Condition in Real Time');
    //   } else {
    //     newItem.device = typeList[0].device;
    //     this.setState({ [mode]: [...typeList, newItem] });
    //   }
    // } else {
    // this.setState({ [mode]: [...typeList, newItem] });
    // }
  };

  handleConditionchange = (e, index, mode) => {
    const list = [...this.state[mode]];
    list[index]['54765'] = e;
    this.setState({ [mode]: list });
  };

  saveRules = async () => {
    const result = scriptGenerator(this.state);
    if (result.errorClearnce) {
      const {
        displayRuleName,
        ruleDesc,
        ruleType,
        batchPriority,
        onChange,
        onContinous,
        replicateDevices,
        ParameterCode,
        Group
      } = this.state;

      for (let prop in this.state) {
        let flage = prop.startsWith('param');
        if (flage) this.setState({ [prop]: [] });
      }
      const replicateList = replicateDevices;
      // this.setState({ replicateDevices: [] });
      try {
        let payLoad = {
          name: displayRuleName,
          displayRuleName: displayRuleName,
          description: ruleDesc,
          payload: {
            ...this.state,
            treeData: [],
            userProfile: {},
            parameterChangeList: [],
            deviceList: [],
            deviceTypeList: [],
            parametersList: [],
            recipientList: []
          },
          tags: result.tagNames,
          script: result.script,
          ParameterCode: ParameterCode,
          ruleType,
          batchPriority,
          onChange,
          onContinous,
          replicate: 'false',
          mailRecipients: this.state.mailRecipients ? this.state.mailRecipients : [],
          url: this.state.url ? this.state.url : [],
          ...(ruleType && ruleType === 'deviceType' ? { Group } : {})
        };

        let data = await saveRule(payLoad);
        if (data.status === 201 || data.status === 200) {
          message.success('Rule Created');
          if (ruleType === 'batch' && replicateList.length > 0) {
            let deviceList = result.tagNames.map((tag) => {
              return tag.device;
            });
            const uniqueDevice = [...deviceList];
            if (uniqueDevice.length > 1) {
              this.setState(defaultState);
              message.error('Rule Cannot Be Replicated For multiple Devices');
              return;
            }
          }
          if (replicateList.length > 0) {
            replicateList.map(async (replicateDevice, i) => {
              let replicatedState = {
                ...replicateRule(this.state, replicateDevice)
              };
              const result = scriptGenerator(replicatedState);
              replicatedState.replicateDevices = [];
              try {
                let repData = await saveRule({
                  name: displayRuleName,
                  displayRuleName: displayRuleName,
                  description: ruleDesc,
                  payload: replicatedState,
                  tags: result.tagNames,
                  script: result.script,
                  ruleType,
                  batchPriority,
                  onChange,
                  onContinous,
                  replicate: 'true',
                  mailRecipients: this.state.mailRecipients ? this.state.mailRecipients : [],
                  parentId: data.data && data.data.Id ? data.data.Id : ''
                });
                if (repData.status === 201 || repData.status === 200) {
                  message.success('Rule Created');
                }
                if (i === replicateList.length - 1) {
                  this.setState(cloneDeep(defaultState));
                }
              } catch (error) {
                if (error.response && error.response.status === 400) {
                  message.error(error.response.data.message);
                }
                if (i === replicateList.length - 1) {
                  this.setState(cloneDeep(defaultState));
                }
              }
            });
          }

          if (this.state.ruleType === 'restApi') {
            this.setState(cloneDeep(predictionDefaultState));
          } else if (this.state.ruleType === 'noDevice') {
            this.setState(cloneDeep(noDeviceDefault));
          } else {
            this.setState(cloneDeep(defaultState));
          }
          this.props.history.push(`/rubus/RulesList`);
        }
      } catch (error) {
        if (error && error.response && error.response.status === 400) {
          message.error(error.response.data.message);
        }
      }
    }
  };

  handleelseif = () => {
    if (!this.state.conelse) {
      const elsifLength = [...this.state.elsifLength];
      let prop = `elseif${this.state.elsifNumber + 1}`;
      elsifLength.push(prop);

      let obj = {
        conif: true,
        conelseif: true,
        conelse: false,
        elsifNumber: this.state.elsifNumber + 1,
        elsifLength,
        [prop]: [
          {
            tagName: '',
            operater: '',
            value: '',
            action: []
          }
        ]
      };

      if (this.state.ruleType === 'realTime') {
        if (this.state.inputList[0].device === '') {
          message.error('Please Select Equipment In If Condition for RealTime Rule');
        } else {
          obj[prop][0].device = this.state.inputList[0].device;
          this.setState(obj);
        }
      } else {
        this.setState(obj);
      }
    }
  };

  handleelse = () => {
    const { elseList } = this.state;
    if (elseList.length === 0) {
      let obj = {
        conif: true,
        conelseif: true,
        conelse: true,
        elseList: [{ tagName: '', operater: '', value: '', action: [] }]
      };
      if (this.state.ruleType === 'realTime') {
        if (this.state.inputList[0].device === '') {
          message.error('Please Select Equipment In If Condition for RealTime Rule');
        } else {
          obj['elseList'][0].device = this.state.inputList[0].device;
          this.setState(obj);
        }
      } else {
        this.setState(obj);
      }
    }
  };

  checkonChange = (values, type, index) => {
    const { translationCache } = this.props;
    let translationAction;
    let keys = Object.keys(translationCache);
    let arrConverter = values.map((action) => {
      let data = keys.filter((labels) => {
        return translationCache[labels] === action;
      });
      if (data[0] === undefined) {
        return [action];
      } else {
        return data;
      }
    });
    translationAction = arrConverter.map((indexedArry) => {
      return indexedArry[0];
    });

    let typeList = {};
    if (index === undefined) {
      typeList = { ...this.state[type] };
      typeList['action'] = translationAction;
    } else {
      typeList = [...this.state[type]];
      typeList[index]['action'] = translationAction;
    }

    this.setState({ [type]: typeList });
  };

  handleDetailsName = (e) => {
    this.setState({ displayRuleName: e.target.value });
  };

  handleDetailsDesc = (e) => {
    this.setState({ ruleDesc: e.target.value });
  };

  editrule = async () => {
    const result = scriptGenerator(this.state);
    const replicateList = this.state.replicateDevices;
    this.setState({
      replicateDevices: replicateList
    });
    // delete this.state.replicateDevices;
    if (result.errorClearnce) {
      const { displayRuleName, ruleDesc, ruleType, batchPriority, onChange, onContinous, Group } =
        this.state;
      let payLoad = {
        name: displayRuleName,
        displayRuleName: displayRuleName,
        description: ruleDesc,
        payload: {
          ...this.state,
          treeData: [],
          userProfile: {},
          parameterChangeList: [],
          deviceList: [],
          deviceTypeList: [],
          parametersList: [],
          recipientList: []
        },
        tags: result.tagNames,
        script: result.script,
        ruleType: ruleType,
        batchPriority: batchPriority,
        onChange: onChange,
        onContinous,
        mailRecipients: this.state.mailRecipients ? this.state.mailRecipients : [],
        url: this.state.url ? this.state.url : [],
        ...(ruleType && ruleType === 'deviceType' ? { Group } : {})
      };
      let data = await editRule(this.props.match.params.id, payLoad);
      if (data && data.status === 200) {
        replicateList &&
          Array.isArray(replicateList) &&
          replicateList.map(async (replicateDevice, i) => {
            let replicatedState = {
              ...replicateRule(this.state, replicateDevice)
            };

            const result = scriptGenerator(replicatedState);
            replicatedState.replicateDevices = [];
            try {
              let repData = await saveRule({
                name: displayRuleName,
                displayRuleName: displayRuleName,
                description: ruleDesc,
                payload: replicatedState,
                tags: result.tagNames,
                script: result.script,
                ruleType,
                batchPriority,
                onChange,
                onContinous,
                replicate: 'true',
                mailRecipients: this.state.mailRecipients ? this.state.mailRecipients : [],
                parentId: data.data && data.data.Id ? data.data.Id : ''
              });
              if (repData.status === 201 || repData.status === 200) {
                message.success('Rule Updated');
              }
              if (i === replicateList.length - 1) {
                this.props.history.push(`/rubus/RulesList`);
              }
            } catch (error) {
              if (i === replicateList.length - 1) {
                this.props.history.push(`/rubus/RulesList`);
              }
            }
          });
        if (replicateList.length === 0) {
          message.success('Rule Updated');
          this.props.history.push(`/rubus/RulesList`);
        }
        message.success('Rule Updated');
      }
    }
  };

  handleRuleType = async (value, mode) => {
    if (value === 'batch' && mode === 'ruleType') {
      //If ruleType is batch,call priority list
      let priorityFrequencyList = await priorityList();
      this.setState({
        [mode]: value,
        priorityTypes: priorityFrequencyList ? priorityFrequencyList : []
      });
    } else {
      this.setState({ [mode]: value });
    }
  };

  handleRecipientList = (value, mode) => {
    if (value && Array.isArray(value) && value.length > 5) {
      message.error('Max 5 Users are allowed');
    } else {
      this.setState({ [mode]: value });
    }
  };
  handleurl = (e, mode) => {
    this.setState({ [mode]: e.target.value });
  };
  handleRulebody = (e, mode) => {
    this.setState({ [mode]: e.target.value });
  };
  handledownTimedeviceSelect = async (value, mode) => {
    let list = { ...this.state[mode] };
    list['device'] = value;
    this.setState({ [mode]: list });
  };
  handleparameterTypeSelect = (value, index, mode) => {
    const list = [...this.state[mode]];
    list[index]['parameterType'] = value;
    this.setState({ [mode]: list });
  };

  handleParameterSelect = async (value, index, mode) => {
    const list = [...this.state[mode]];
    list[index]['parameterName'] = value;
    this.setState({
      [mode]: list,
      parameter: value,
      indexDevice: index,
      enable: true
    });
  };
  handleSelectHierarchy = async (value, index, mode, code) => {
    let { parameter } = this.state;
    // let parameterName = t
    const list = [...this.state[mode]];
    list[index]['HierarchyId'] = value;
    this.setState({ [mode]: list });
    if (code === 'initail') {
      let data = await getHierarchyLevels(value, this.state.inputList[0].parameterName);
      if (data) {
        this.setState({
          treeData: data,
          selectedDevices: []
        });
      }
    } else {
      let data = await getHierarchyLevels(value, parameter);
      if (data) {
        this.setState({
          treeData: data,
          selectedDevices: []
        });
      }
    }
  };

  handledeviceSelect = async (value, mode, index) => {
    const list = [...this.state[mode]];
    list[index]['device'] = value;
    if (this.state.selectedDevices.includes(value)) {
      this.setState({ [mode]: list });
    } else {
      try {
        if (this.state.tabActivationkey === 'DeviceRules') {
          // this.state.selectedDevices.push(value);
          let parametersData = await getparameterList(value, this.state.parameter);
          this.setState({
            [mode]: list,
            parametersList: parametersData.data,
            selectedEquipment: [value],
            selectedDevices: [value]
          });
        } else if (this.state.tabActivationkey === 'DeviceTypeRules') {
          // this.state.selectedDevices.push(value);
          list[index]['deviceType'] = value;
          let parametersList = await getparameterListByDeviceType(this.state.parameter, value);
          this.setState({ [mode]: list, parametersList });
        } else {
          // this.state.selectedDevices.push(value);
          this.setState({ selectedDevices: [value] });
          let columsData = await getColumnsList([value]);
          // let columsData = await getColumnsList(this.state.selectedDevices);
          this.setState({ [mode]: list, columnList: columsData.data });
        }
      } catch (error) {
        message.error('Could Not Able To Load ParametersList');
      }
    }
  };

  handleTableDeviceSelect = async (value, index, mode) => {
    const list = [...this.state[mode]];
    list[index]['tableDevice'] = value;
    this.setState({ [mode]: list });
  };

  handleVisibleChange = (visible, type) => {
    this.setState({ [type]: visible });
  };

  hanblePopoverOnclick = (index, mode, type, dignosis, flag) => {
    if (type === 'close') {
      let list = [...this.state[mode]];
      list[index][dignosis] = '';
      this.setState({ [flag]: false });
    } else {
      this.setState({ [dignosis]: false });
    }
  };

  handleTagSelect = (value, index, mode) => {
    const list = [...this.state[mode]];
    list[index]['tagName'] = value;
    this.setState({ [mode]: list, ParameterCode: value });
  };

  handleDelay = (value, type, index) => {
    let time = value._d.toTimeString().split(' ')[0];
    let splitTime = time.split(':');
    let delayTime =
      parseInt(splitTime[0] * 3600) + parseInt(splitTime[1] * 60) + parseInt(splitTime[2]);

    let typeList = {};
    if (index === undefined) {
      typeList = { ...this.state[type] };
      typeList['delay'] = [delayTime, time];
    } else {
      typeList = [...this.state[type]];
      typeList[index]['delay'] = [delayTime, time];
    }
    this.setState({ [type]: typeList });
  };

  handleFrequency = (value, type, index) => {
    let time = value._d.toTimeString().split(' ')[0];
    let splitTime = time.split(':');
    let frequencyTime =
      parseInt(splitTime[0] * 3600) + parseInt(splitTime[1] * 60) + parseInt(splitTime[2]);

    let typeList = {};
    if (index === undefined) {
      typeList = { ...this.state[type] };
      typeList['frequency'] = [frequencyTime, time];
    } else {
      typeList = [...this.state[type]];
      typeList[index]['frequency'] = [frequencyTime, time];
    }
    this.setState({ [type]: typeList });
  };

  handleRadioButton = (e) => {
    this.setState({ onChange: e.target.checked });
  };

  handleContinous = (e) => {
    this.setState({ onContinous: e.target.checked });
  };

  handlePickyChange = (values) => {
    this.setState({ replicateDevices: values });
  };

  handleTabs = (value) => {
    switch (value) {
      case 'DeviceRules': {
        let devicObj = cloneDeep(defaultState);
        devicObj.tabActivationkey = value;
        devicObj.ruleName = this.state.ruleName;
        devicObj.ruleDesc = this.state.ruleDesc;
        this.setState(devicObj);
        break;
      }
      case 'DeviceTypeRules': {
        let deviceTypeObj = cloneDeep(deviceTypedefaultState);
        deviceTypeObj.tabActivationkey = value;
        deviceTypeObj.ruleName = this.state.ruleName;
        deviceTypeObj.ruleDesc = this.state.ruleDesc;
        this.setState(deviceTypeObj);
        break;
      }
      case 'RestApi': {
        let predicObj = cloneDeep(predictionDefaultState);
        predicObj.tabActivationkey = value;
        predicObj.ruleName = this.state.ruleName;
        predicObj.ruleDesc = this.state.ruleDesc;
        this.setState(predicObj);
        break;
      }
      case 'noDevice': {
        let nodevicObj = cloneDeep(noDeviceDefault);
        nodevicObj.tabActivationkey = value;
        nodevicObj.ruleName = this.state.ruleName;
        nodevicObj.ruleDesc = this.state.ruleDesc;
        this.setState(nodevicObj);
        break;
      }
      case 'table': {
        let tableObj = cloneDeep(tableState);
        tableObj.tabActivationkey = value;
        tableObj.ruleName = this.state.ruleName;
        tableObj.ruleDesc = this.state.ruleDesc;
        this.setState(tableObj);
        break;
      }
      default:
        break;
    }
  };

  render() {
    const { displayRuleName, ruleDesc, indexDevice, enable, treeData } = this.state;

    const { translationCache, userProfile } = this.props;
    const {
      handledeviceSelect,
      handleParameterSelect,
      handledownTimedeviceSelect,
      handleelseif,
      handleelse,
      checkonChange,
      handleRuleType,
      handleRecipientList,
      handleurl,
      handleRulebody,
      handleDelay,
      handleSelectHierarchy,
      handleFrequency,
      handleTagSelect,
      handlePickyChange,
      handleInputChange,
      handleAddClick,
      handleRemoveClick,
      handleRadioButton,
      handleContinous,
      handleVisibleChange,
      hanblePopoverOnclick,
      handleTextAreaChange,
      handleConditionchange,
      handleTableDeviceSelect,
      handleparameterTypeSelect
    } = this;

    const Methods = {
      handledeviceSelect,
      handleParameterSelect,
      handledownTimedeviceSelect,
      handleelseif,
      handleelse,
      handleRuleType,
      handleRecipientList,
      handleurl,
      handleRulebody,
      handleDelay,
      handleSelectHierarchy,
      handleFrequency,
      checkonChange,
      handleTagSelect,
      handlePickyChange,
      handleInputChange,
      handleRadioButton,
      handleContinous,
      handleAddClick,
      handleRemoveClick,
      handleVisibleChange,
      hanblePopoverOnclick,
      handleTextAreaChange,
      handleConditionchange,
      handleTableDeviceSelect,
      handleparameterTypeSelect
    };
    let permissionsArray = [];
    userProfile &&
      userProfile['belongsTo'] &&
      userProfile['belongsTo']['sites'] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')]['role'] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')]['role']['Permission']
        .filter((item) => item.resource === 'rule_engine')
        .map((name) => permissionsArray.push(name.action));
    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 69 }}>
        <div style={{ margin: '10px 0px' }}>
          <Button
            type="primary"
            onClick={() => {
              history.goBack();
            }}
          >
            {' '}
            <LeftOutlined />{' '}
            {translationCache && translationCache[`${constants.Go_Back}`]
              ? translationCache[`${constants.Go_Back}`]
              : `${constants.Go_Back}`}{' '}
          </Button>
        </div>

        <div>
          <Row style={{ marginBottom: '1%' }}>
            <Col span={12}>
              <BlockedLabel>
                {translationCache && translationCache[`${constants.Rule_Name}`]
                  ? translationCache[`${constants.Rule_Name}`]
                  : `${constants.Rule_Name}`}
              </BlockedLabel>
              <Input
                style={{ width: '55%' }}
                placeholder={
                  translationCache && translationCache[`${constants.phName}`]
                    ? translationCache[`${constants.phName}`]
                    : `${constants.phName}`
                }
                name="displayRuleName"
                value={displayRuleName}
                maxlength="25"
                onChange={(e) => {
                  e.persist();
                  this.handleDetailsName(e);
                }}
              />
            </Col>
            <Col span={12}>
              <BlockedLabel>
                {translationCache &&
                translationCache[`${constants.Rule_Description}`] &&
                translationCache[`${constants.Rule_Description}`]
                  ? translationCache[`${constants.Rule_Description}`]
                  : `${constants.Rule_Description}`}
              </BlockedLabel>
              <Input
                style={{ width: '55%' }}
                placeholder={
                  translationCache &&
                  translationCache[`${constants.phDescription}`] &&
                  translationCache[`${constants.phDescription}`]
                    ? translationCache[`${constants.phDescription}`]
                    : `${constants.phDescription}`
                }
                maxlength="50"
                name="ruleDesc"
                value={ruleDesc}
                onChange={(e) => this.handleDetailsDesc(e)}
              />
            </Col>
          </Row>
          <Tabstyled
            style={{ marginTop: '10px !important' }}
            activeKey={this.state.tabActivationkey}
            onChange={this.handleTabs}
            type="card"
          >
            <TabPane
              tab={
                translationCache && translationCache[`${constants.deviceRules}`]
                  ? translationCache[`${constants.deviceRules}`]
                  : `${constants.deviceRules}`
              }
              key="DeviceRules"
            >
              <DeviceRuleView
                selectedDevices={this.state.selectedEquipment}
                replicateDevices={this.state.replicateDevices}
                // userProfile={this.state.userProfile}
                edit={this.state.edit}
                // disabledParamTypeDevice={this.state.disabledParamTypeDevice}
                onContinous={this.state.onContinous}
                indexDevice={indexDevice}
                enable={enable}
                handledeviceSelect={this.handledeviceSelect}
                treeData={treeData}
                config={this.state}
                Methods={Methods}
                translationCache={this.props.translationCache}
              />
            </TabPane>
            <TabPane
              tab={
                translationCache && translationCache[`${constants.deviceTypeRules}`]
                  ? translationCache[`${constants.deviceTypeRules}`]
                  : `${constants.deviceTypeRules}`
              }
              key="DeviceTypeRules"
            >
              <DeviceTypeRule
                // userProfile={this.state.userProfile}
                // disabledParamTypeDevice={this.state.disabledParamTypeDevice}
                onContinous={this.state.onContinous}
                indexDevice={indexDevice}
                enable={enable}
                handledeviceSelect={this.handledeviceSelect}
                treeData={treeData}
                config={this.state}
                //  .deviceTypedefaultState = { deviceTypedefaultState}
                Methods={Methods}
                translationCache={this.props.translationCache}
              />
            </TabPane>
            {/* <TabPane
              tab={
                translationCache &&
                translationCache[`${constants.nodeviceRules}`] &&
                translationCache[`${constants.nodeviceRules}`]
                  ? translationCache[`${constants.nodeviceRules}`]
                  : `${constants.nodeviceRules}`
              }
              key="noDevice"
            >
              <NodeviceView
                config={this.state}
                Methods={Methods}
                translationCache={this.props.translationCache}
                language={this.props.language}
              />
            </TabPane>
            <TabPane
              tab={
                translationCache &&
                translationCache[`${constants.restApiRules}`] &&
                translationCache[`${constants.restApiRules}`]
                  ? translationCache[`${constants.restApiRules}`]
                  : `${constants.restApiRules}`
              }
              key="RestApi"
            >
              <Restapi
                config={this.state}
                Methods={Methods}
                translationCache={this.props.translationCache}
                language={this.props.language}
              />
            </TabPane>
            <TabPane
              tab={
                translationCache &&
                translationCache[`${constants.tableRules}`] &&
                translationCache[`${constants.tableRules}`]
                  ? translationCache[`${constants.tableRules}`]
                  : `${constants.tableRules}`
              }
              key="table"
            >
              <TableRuleView
                config={this.state}
                Methods={Methods}
                translationCache={this.props.translationCache}
                language={this.props.language}
              />
            </TabPane> */}
          </Tabstyled>
          <div style={{ display: 'flex', flexDirection: 'row-reverse', margin: '10px 0px' }}>
            {this.state.edit ? (
              <Button
                type="primary"
                onClick={() => {
                  this.setState({
                    replicateDevices: this.state.replicateDevice,
                    selectedEquipment: this.state.selectedDevices
                  });
                  this.editrule();
                }}
                disabled={!permissionsArray.includes('update') ? true : false}
              >
                {translationCache && translationCache[`${constants.update}`]
                  ? translationCache[`${constants.update}`]
                  : `${constants.update}`}
              </Button>
            ) : (
              <Button
                type="primary"
                disabled={!permissionsArray.includes('create') ? true : false}
                onClick={() => {
                  this.saveRules();
                }}
              >
                {translationCache && translationCache[`${constants.save}`]
                  ? translationCache[`${constants.save}`]
                  : `${constants.save}`}
              </Button>
            )}
          </div>
        </div>
      </StyledDashboard>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(RuleEngine);
