import axios from 'axios';
import localStorage from '../../../../utils/localStorage';

import { message } from 'antd';
export async function getList(api) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${api}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      message.info(err && err.response && err.response.data && err.response.data.message);
    });
}
export async function submitTask(api, payload) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${api}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response && err.response.data && err.response.data.message) {
        message.info(err.response.data.message);
      }
    });
}
export async function TablesList(api) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const recipientListApi = {
    method: 'GET',
    url: `${api}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(recipientListApi)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function SchemasList(api) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const recipientListApi = {
    method: 'POST',
    url: `${api}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(recipientListApi)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function TablesListBySchema(api, payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const recipientListApi = {
    method: 'POST',
    url: `${api}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      Schema: payload
    }
  };
  return axios(recipientListApi)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function getMetaData(api, param, Id) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${api}?${param}=${Id}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      message.info(err && err.response && err.response.data && err.response.data.message);
    });
}
export async function updateTask(api, payload, Id) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'PATCH',
    url: `${api}?SiteId=${siteId}&Id=${Id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response && err.response.data && err.response.data.message) {
        message.info(err.response.data.message);
      }
    });
}
export async function deleteById(payload) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let data = {
    ...payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
