import React from 'react';
// import moment from 'moment';
import themeSettings from './Utils/themeSettings.json';
// import { StyledComponent, StyledForm } from './CSS/style';

// import history from '../../../commons/history';
import { getTranslationCache } from '../../../selectors/language';
import { API_BACKUP } from '../../../commons/api';
import { Button, Form, Input, Select, message } from 'antd';
import { createStructuredSelector } from 'reselect';
import { getUserProfile } from '../../../selectors/layout';
import { connect } from 'react-redux';
import { submitTask, SchemasList } from './Utils/FormCalls';
const { Option } = Select;
const APIList = {
  createData: `${API_BACKUP.ConfigCreate}`,
  GetSchemasList: `${API_BACKUP.ConfigGetSchemas}`
  // updateData: '/api/backup/tasks/update'
};
class Configuration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      translationCache: props.translationCache || {},
      userProfile: props && props.userProfile ? props.userProfile : {},
      typeList: {},
      schemaList: {},
      checkedVal: 'Active',
      selectedType: ''
    };
    this.formRef = React.createRef();
  }
  componentDidMount = async () => {
    let schemaList = await SchemasList(APIList.GetSchemasList);

    // let path = history.location.pathname.split('/rubus/settingsPage/Configuration/');
    // let Id = path && path[1] ? path[1] : null;
    // if (Id !== null) {
    //   let data = await getMetaData(APIList.getById, 'Id', Id);

    // }
    this.setState({
      schemaList: schemaList
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (
      this.props.userProfile !== prevProps.userProfile ||
      this.props.translationCache !== prevProps.translationCache
    ) {
      this.setState({
        userProfile: this.props.userProfile,
        translationCache: this.props.translationCache
      });
    }
  };

  onFormSubmit = () => {
    // let { checkedVal, formObject } = this.state;
    this.formRef.current
      .validateFields()
      .then(async (values) => {
        values = {
          ...this.formRef.current.getFieldsValue()
          // Status: checkedVal
        };
        if (values && values.Id === undefined) {
          let response = await submitTask(APIList.createData, values);
          message.success(response && response.message ? this.translation(response.message) : '');
          if (this.formRef.current) {
            this.formRef.current.setFieldsValue({
              Schema: null,
              TemporaryFilesPath: null,
              StorageLocation: null,
              ServerCredentials: null,
              StoragePath: null
            });
          }
          this.setState({
            formObject: {}
            // checkedVal: 'Active'
          });
        }
        // else{
        //   let response = await updateTask(APIList.updateData, values,values.Id);
        //   message.success(response && response.message ? this.translation(response.message) : '');
        //   if (this.formRef.current) {
        //     this.formRef.current.setFieldsValue({
        //       Schema:null,
        //       TemporaryFilesPath:null,
        //       StorageLocation:null,
        //       ServerCredentials:null,
        //       StoragePath:null
        //     });
        //   }
        //   this.setState({
        //     formObject: {},
        //     // checkedVal: 'Active'
        //   });
        // }
      })
      .catch((error) => console.log(error));
  };

  resetFields = () => {
    if (this.formRef.current) {
      this.formRef.current.setFieldsValue({
        Schema: null,
        TemporaryFilesPath: null,
        StorageLocation: null,
        ServerCredentials: null,
        StoragePath: null
      });
    }
    this.setState({
      formObject: {}
      // checkedVal: 'Active'
    });
  };

  onCheckboxChange = (e) => {
    if (e.target.checked === true) {
      this.setState({ checkedVal: 'Active' });
    } else if (e.target.checked === false) {
      this.setState({ checkedVal: 'InActive' });
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const { formObject, schemaList } = this.state;

    // const validateMessages = {
    //   required: this.translation('${label}') + this.translation('is required!')
    // };
    const formItemLayout = { width: '100%', flex: '50%' };

    return (
      <Form
        ref={this.formRef}
        initialValues={formObject}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item name="Id"></Form.Item>
        <Form.Item
          label={this.translation('Schema')}
          name="Schema"
          // rules={[{ required: true }]}
          style={formItemLayout}
        >
          <Select style={{ width: '400px' }} placeholder={this.translation('Please Select Schema')}>
            {schemaList &&
              Array.isArray(schemaList) &&
              schemaList.map((table) => {
                return (
                  <Option value={table.Value} key={table.Key}>
                    {table.Key}
                  </Option>
                );
              })}{' '}
          </Select>
        </Form.Item>
        <Form.Item
          label={this.translation('Temporary Files Path')}
          name="TemporaryFilesPath"
          // rules={[{ required: true }]}
          style={formItemLayout}
        >
          <Input
            // disabled={formObject && formObject.DataBase ? true : false}
            style={{ width: '400px' }}
            placeholder={this.translation('Please Enter Temporary files path')}
          ></Input>
        </Form.Item>
        <Form.Item
          label={this.translation('Storage Location')}
          name="StorageLocation"
          // rules={[{ required: true }]}
          style={formItemLayout}
        >
          <Select
            //   mode='multiple'
            style={{ width: '400px' }}
            placeholder={this.translation('Please Select Storage location')}
          >
            {/* {schemaList &&
                  Array.isArray(schemaList) &&
                  schemaList.map((table) => {
                    return ( */}
            <Option value={'Azure Blob'} key={'Azure Blob'}>
              {'Azure Blob'}
            </Option>
            <Option value={'AWS S3'} key={'AWS S3'}>
              {'AWS S3'}
            </Option>
            <Option value={'Local Infra (FTP)'} key={'Local Infra (FTP)'}>
              {'Local Infra (FTP)'}
            </Option>
            {/* );
                  })}{' '} */}
          </Select>
        </Form.Item>

        <Form.Item
          label={this.translation('Server Credentials')}
          name="ServerCredentials"
          // rules={[{ required: true }]}
          style={formItemLayout}
        >
          <Input
            // disabled={formObject && formObject.DataBase ? true : false}
            style={{ width: '400px' }}
            placeholder={this.translation('Please Enter Server credentials')}
          ></Input>
        </Form.Item>
        <Form.Item
          label={this.translation('Storage Path')}
          name="StoragePath"
          // rules={[{ required: true }]}
          style={formItemLayout}
        >
          <Input
            // disabled={formObject && formObject.DataBase ? true : false}
            style={{ width: '400px' }}
            placeholder={this.translation('Please Enter Storage Path')}
          ></Input>
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <Form.Item>
            <Button
              style={{ marginRight: 8 }}
              theme={themeSettings}
              type="primary"
              onClick={() => this.onFormSubmit()}
              data-testid="submitButton"
            >
              {this.translation('Submit')}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              style={{ marginRight: 8 }}
              theme={themeSettings}
              type="primary"
              onClick={() => this.resetFields()}
            >
              {this.translation('Cancel')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(Configuration);
