import React from 'react';
import moment from 'moment';
import themeSettings from './Utils/themeSettings.json';
import { StyledComponent, StyledForm } from './CSS/style';

import history from '../../../commons/history';
import { getTranslationCache } from '../../../selectors/language';
import { API_BACKUP } from '../../../commons/api';
import { Button, Form, Input, Select, message, DatePicker } from 'antd';
import { createStructuredSelector } from 'reselect';
import { getUserProfile } from '../../../selectors/layout';
import { connect } from 'react-redux';
import {
  submitTask,
  SchemasList,
  TablesListBySchema,
  getMetaData,
  updateTask
} from './Utils/FormCalls';
const { Option } = Select;
const APIList = {
  getById: `${API_BACKUP.TasksGetById}`,
  getTables: `${API_BACKUP.TasksGetTablesBySchemas}`,
  createData: `${API_BACKUP.TasksCreate}`,
  updateData: `${API_BACKUP.TasksUpdate}`,
  GetSchemasList: `${API_BACKUP.TasksGetSchemas}`
};
class TaskSchedulerDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      translationCache: props.translationCache || {},
      userProfile: props && props.userProfile ? props.userProfile : {},
      tablesList: {},
      selectedType: ''
    };
    this.hRef = React.createRef();
  }
  componentDidMount = async () => {
    let schemaList = await SchemasList(APIList.GetSchemasList);

    let path = history.location.pathname.split('/rubus/TaskSchedulerDetails/');
    let Id = path && path[1] ? path[1] : null;
    if (Id !== null) {
      let data = await getMetaData(APIList.getById, 'Id', Id);
      let tablesList = [];
      tablesList = await TablesListBySchema(APIList.getTables, data && data[0] && data[0].Schema);
      this.setState({ selectedSchema: data[0] && data[0].Schema, tablesList });
      // if (Id && Id !== null) {
      // let data = await getMetaData(APIList.getById, 'Id', Id);

      let formObject = {
        ...data[0],
        StartDate: data && data[0] && data[0].StartDate ? moment(data[0].StartDate) : [],
        EndDate: data && data[0] && data[0].EndDate ? moment(data[0].EndDate) : []
      };
      if (this.hRef.current) {
        this.hRef.current.setFieldsValue({
          ...formObject,
          StartDate: data && data[0] && data[0].StartDate ? moment(data[0].StartDate) : [],
          EndDate: data && data[0] && data[0].EndDate ? moment(data[0].EndDate) : []
        });
      }
      this.setState({ formObject });
    }
    this.setState({
      // tablesList: tablesList,
      schemaList
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (
      this.props.userProfile !== prevProps.userProfile ||
      this.props.translationCache !== prevProps.translationCache
    ) {
      this.setState({
        userProfile: this.props.userProfile,
        translationCache: this.props.translationCache
      });
    }
  };

  onFormSubmit = () => {
    // let { formObject } = this.state;
    this.hRef.current
      .validateFields()
      .then(async (values) => {
        values = {
          ...this.hRef.current.getFieldsValue()
          // Status: checkedVal
        };
        if (values && values.Id === undefined) {
          let response = await submitTask(APIList.createData, values);
          message.success(response && response.message ? this.translation(response.message) : '');
          history.push('/rubus/TaskScheduler');
        } else {
          let response = await updateTask(APIList.updateData, values, values.Id);
          message.success(response && response.message ? this.translation(response.message) : '');
          history.push('/rubus/TaskScheduler');
        }

        // this.setState({ formObject: metaData });
        // message.success(response && response.message ? this.translation(response.message) : '');
        // history.push('/rubus/TaskScheduler');
      })
      .catch(() => {});
  };

  resetFields = () => {
    let formData = this.hRef.current && this.hRef.current.getFieldValue();
    if (formData && Object.keys(formData).length > 0) {
      this.hRef.current.setFieldsValue({
        Name: null,
        StartDate: null,
        EndDate: null,
        Schema: [],
        TableName: [],
        DataStorageLocation: null
      });
    }
    this.setState({
      formObject: {}
      // checkedVal: 'Active'
    });
  };

  onCheckboxChange = (e) => {
    if (e.target.checked === true) {
      this.setState({ checkedVal: 'Active' });
    } else if (e.target.checked === false) {
      this.setState({ checkedVal: 'InActive' });
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  onSchemaChange = async (values) => {
    this.hRef.current &&
      this.hRef.current.setFieldsValue({
        TableName: []
      });
    let tablesList = [];
    tablesList = await TablesListBySchema(APIList.getTables, values);
    this.setState({ selectedSchema: values, tablesList });
  };
  render() {
    const { formObject, view, tablesList, schemaList } = this.state;

    const validateMessages = {
      required: this.translation('${label}') + this.translation('is required!')
    };
    const formItemLayout = { width: '100%', flex: '50%' };

    return (
      <StyledComponent
        theme={themeSettings}
        style={{
          padding: '30px 30px',
          minHeight: window.innerHeight - 143,
          margin: '5px'
        }}
      >
        <div>
          <Button
            type="primary"
            onClick={() => {
              history.push('/rubus/TaskScheduler');
            }}
          >
            Go Back
          </Button>
        </div>
        <StyledForm
          name="taskSchedulerDetails"
          ref={this.hRef}
          autoComplete="off"
          initialValues={formObject}
          theme={themeSettings}
          layout="horizontal"
          labelCol={{ flex: '200px' }}
          labelAlign="right"
          onFinish={this.onFormSubmit}
          onFinishFailed={this.onFinishFailed}
          disabled={view === true ? true : false}
          labelWrap
          validateMessages={validateMessages}
          wrapperCol={{ flex: 1 }}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
            <Form.Item name="Id"></Form.Item>
            <Form.Item
              label={this.translation('Name')}
              name="Name"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Input
                style={{ width: '400px' }}
                placeholder={this.translation('Please Enter Name')}
              ></Input>
            </Form.Item>
            <Form.Item
              name="StartDate"
              label={this.translation('Start Date')}
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <DatePicker
                style={{ width: '400px' }}
                allowClear={false}
                showTime={{ format: 'HH:mm:ss' }}
                // defaultValue={moment()}
                format="YYYY-MM-DD HH:mm:ss"
                // onOk={(date) => this.handleRealTimeDateInputChange(date)}
                // disabled={ButtonKey === 'stop' || ButtonKey === 'edit' ? true : false}
                // disabledDate={(current) => {
                //   return moment().add(-1, 'days') >= current;
                // }}
                disabledDate={
                  (d) =>
                    !d ||
                    d.isAfter(
                      this.hRef.current &&
                        this.hRef.current !== null &&
                        this.hRef.current.getFieldValue() &&
                        this.hRef.current.getFieldValue('EndDate')
                        ? moment(
                            this.hRef.current.getFieldValue() &&
                              this.hRef.current.getFieldValue('EndDate')
                          ).format('YYYY-MM-DD HH:mm:ss')
                        : ''
                    )
                  // || d.isBefore(moment() ? moment().format('YYYY-MM-DD HH:mm:ss') : '')
                }
              />
            </Form.Item>
            <Form.Item
              name="EndDate"
              label={this.translation('End Date')}
              style={formItemLayout}
              rules={[{ required: true }]}
            >
              <DatePicker
                style={{ width: '400px' }}
                allowClear={false}
                showTime={{ format: 'HH:mm:ss' }}
                format="YYYY-MM-DD HH:mm:ss"
                // minDate={moment().toDate()}
                // disabled={ButtonKey === 'stop' ? true : false}
                // onOk={(date) => this.handleRealTimeDateInputChange(date)}
                disabledDate={
                  (d) =>
                    !d ||
                    d.isBefore(
                      this.hRef.current &&
                        this.hRef.current !== null &&
                        this.hRef.current.getFieldValue() &&
                        this.hRef.current.getFieldValue('StartDate')
                        ? moment(
                            this.hRef.current.getFieldValue() &&
                              this.hRef.current.getFieldValue('StartDate')
                          ).format('YYYY-MM-DD HH:mm:ss')
                        : ''
                    )
                  //  ||
                  // d.isBefore(moment().format('YYYY-MM-DD HH:mm:ss'))
                }
              />
            </Form.Item>
            <Form.Item
              label={this.translation('Schema')}
              name="Schema"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Select
                mode="multiple"
                style={{ width: '400px' }}
                placeholder={this.translation('Please Select Schema')}
                onChange={this.onSchemaChange}
              >
                {schemaList &&
                  Array.isArray(schemaList) &&
                  schemaList.map((table) => {
                    return (
                      <Option value={table.Value} key={table.Key}>
                        {table.Key}
                      </Option>
                    );
                  })}{' '}
              </Select>
            </Form.Item>
            <Form.Item
              label={this.translation('Table Selection')}
              name="TableName"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Select
                mode="multiple"
                style={{ width: '400px' }}
                placeholder={this.translation('Please Select Table')}
              >
                {tablesList &&
                  Array.isArray(tablesList) &&
                  tablesList.map((table) => {
                    return (
                      <Option value={table.Value} key={table.Key}>
                        {table.Key}
                      </Option>
                    );
                  })}{' '}
              </Select>
            </Form.Item>

            <Form.Item
              label={this.translation('Data Storage Location')}
              name="DataStorageLocation"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Input
                // disabled={formObject && formObject.DataBase ? true : false}
                style={{ width: '400px' }}
                placeholder={this.translation('Please Enter Data Storage Location')}
              ></Input>
            </Form.Item>
          </div>
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Form.Item>
              <Button
                style={{ marginRight: 8 }}
                theme={themeSettings}
                type="primary"
                htmlType="submit"
                data-testid="submitButton"
              >
                {this.translation('Submit')}
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                style={{ marginRight: 8 }}
                theme={themeSettings}
                type="primary"
                onClick={() => this.resetFields()}
              >
                {this.translation('Cancel')}
              </Button>
            </Form.Item>
          </div>
        </StyledForm>
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(TaskSchedulerDetails);
