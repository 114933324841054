/**
 * @author Swathi
 * @description This Component is mainly for Creating Jobplans and Task
 * @View Table with crud operations of Jobplanes
 */
import React, { Component } from 'react';
import { Form, message, Select, DatePicker, Input, Button, TimePicker } from 'antd';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import _ from 'lodash';
import history from '../../../../../commons/history';
import {
  getDeviceList,
  getSitesList,
  getUsersList,
  getLocationByAsset,
  submitScheduleInspection,
  // getJobPlansList,
  getJobPlanById,
  getMasterList,
  getStatusListPM,
  getscheduleInspectByID,
  getWorkTypesEInspec,
  getFrequency,
  getJobPlansListByEquipment
  // submitWorkOrder
} from '../../../Utils/FormCalls';
import axios from 'axios';
import Highlighter from 'react-highlight-words';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { constant } from '../../../Utils/constants';
import translation from '../../../Utils/translation';
import themeSettings from '../../../Utils/themeSettings.json';
import TableSelect from '../../../Widgets/TableSelect';
import { getTranslationCache } from '../../../../../selectors/language';
import {
  StyledBox,
  StyledBoxLabel,
  StyledButton,
  StyledComponent,
  StyledForm,
  StyledModal
} from '../../../CSS/style';
import InputComponent from '../../../Widgets/InputComponent';
import { decode as base64_decode } from 'base-64';
import localStorage from '../../../../../utils/localStorage';
import { getUserProfile } from '../../../../../selectors/layout';
import { API_DEVICE, API_INSPECTIONCONFIG } from '../../../../../commons/api';
const { Option } = Select;
const urlList = {
  list: API_INSPECTIONCONFIG.GET
};
class PMDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: [],
      sitesList: [],
      statusList: [],
      usersList: [],
      workTypesList: [],
      visible: false,
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {},
      JobPlansList: [],
      InspectionData: [],
      frequencyObject: {}
    };
  }
  formHeader = React.createRef();
  componentDidMount = async () => {
    this._getDataById();
    let value = 0;
    let days = [];
    for (let i = value + 1; i <= 31; i++) {
      days.push(i);
    }
    this.handleChange(moment().add(1, 'days'), 'EffectiveStartDate');
    this.setState({ days });
    let inspectionMasterList = await getMasterList(urlList.list);
    this.setState({ inspectionMasterList: inspectionMasterList });

    let sitesList = await getSitesList();
    let formObject = this.formHeader.current.getFieldsValue();
    formObject = {
      ...formObject,
      SiteId: sitesList && sitesList[0] && sitesList[0].Id ? sitesList[0].Id : ''
    };
    this.formHeader.current.setFieldsValue(formObject);
    this.setState({ sitesList: sitesList, formObject });
    try {
      let deviceList = await getDeviceList();
      this.setState({ deviceList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let sitesList = await getSitesList();
      this.setState({ sitesList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let usersList = await getUsersList();
      this.setState({ usersList: usersList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    let workTypes = await getWorkTypesEInspec('PM');
    this.setState({ workTypesList: workTypes });

    try {
      let Status = await getStatusListPM();
      this.setState({ statusList: Status });
      if (this.state.formObject && !this.state.formObject.Id) {
        this.handleChange(
          Status && Status[0] && Status[0]['Value'] ? Status[0]['Value'] : null,
          'StatusId'
        );
      }
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let frequencyList = await getFrequency();
      let frequencyObject = {};
      frequencyList &&
        Array.isArray(frequencyList) &&
        frequencyList.map((freq) => {
          frequencyObject[freq.Key] = freq.Value;
          return {};
        });
      this.setState({ frequencyList, frequencyObject });
    } catch (error) {
      message.warn(`${error.message}`);
    }

    // try {
    //   let JobPlansList = await getJobPlansList('Active');
    //   this.setState({ JobPlansList: JobPlansList });
    // } catch (error) {
    //   message.warn(`${error.message}`);
    // }
    try {
      let Status = await getStatusListPM();
      this.setState({ statusList: Status });
      if (formObject && !formObject.Id) {
        this.handleChange(
          Status && Status[0] && Status[0]['Value'] ? Status[0]['Value'] : null,
          'StatusId'
        );
      }
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };
  _getDataById = async (Id) => {
    let formObject = {};
    let path = history.location.pathname.split('/rubus/AMM/PreventiveMaintenanceDetails/');
    let formId = base64_decode(path[1]);
    if (formId !== 'new') {
      let data = await getscheduleInspectByID(formId);
      _.cloneDeepWith(data, (value) => {
        return value && value !== null
          ? {
              ...value,
              ...(value['CreatedDate'] = value.CreatedDate
                ? moment.utc(value.CreatedDate, 'YYYY-MM-DD HH:mm:ss').local()
                : null),
              ...(value['EffectiveStartDate'] = value.EffectiveStartDate
                ? moment.utc(value.EffectiveStartDate, 'YYYY-MM-DD HH:mm:ss').local()
                : null),
              ...(value['EffectiveEndDate'] = value.EffectiveEndDate
                ? moment.utc(value.EffectiveEndDate, 'YYYY-MM-DD HH:mm:ss').local()
                : null),
              ...(value['CreationTime'] = value.CreationTime
                ? moment.utc(value.CreationTime, 'YYYY-MM-DD HH:mm:ss').local()
                : null)
            }
          : _.noop();
      });
      formObject = {
        ...data,
        SiteId: localStorage.get('currentSite') ? parseInt(localStorage.get('currentSite')) : ''
      };
      this.getJobPlanList(data.Equipment);
      let inspectionMasterList = await getMasterList(urlList.list);
      this.formHeader.current && this.formHeader.current.setFieldsValue(formObject);
      this.setState({ formObject, inspectionMasterList });
    } else if (Id) {
      let data = await getscheduleInspectByID(Id);
      _.cloneDeepWith(data, (value) => {
        return value && value !== null
          ? {
              ...value,
              ...(value['CreatedDate'] = value.CreatedDate
                ? moment.utc(value.CreatedDate, 'YYYY-MM-DD HH:mm:ss').local()
                : null),
              ...(value['EffectiveStartDate'] = value.EffectiveStartDate
                ? moment.utc(value.EffectiveStartDate, 'YYYY-MM-DD HH:mm:ss').local()
                : null),
              ...(value['EffectiveEndDate'] = value.EffectiveEndDate
                ? moment.utc(value.EffectiveEndDate, 'YYYY-MM-DD HH:mm:ss').local()
                : null),
              ...(value['CreationTime'] = value.CreationTime
                ? moment.utc(value.CreationTime, 'YYYY-MM-DD HH:mm:ss').local()
                : null)
            }
          : _.noop();
      });
      formObject = {
        ...data,
        SiteId: localStorage.get('currentSite') ? parseInt(localStorage.get('currentSite')) : ''
      };

      this.getJobPlanList(data.Equipment);
      let inspectionMasterList = await getMasterList(data.list);
      this.formHeader.current && this.formHeader.current.setFieldsValue(formObject);
      this.setState({ formObject, inspectionMasterList });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.history.location !== prevProps.history.location) {
      this.onReset();
      this._getDataById();
    }
  }
  handleChange = async (value, key) => {
    let { deviceList, usersList } = this.state;
    let formObject = this.formHeader.current && this.formHeader.current.getFieldsValue();
    if (key === 'Equipment') {
      let selectedDevice = deviceList.filter((item) => item.AliasCode === value);
      let location = await getLocationByAsset(selectedDevice[0] && selectedDevice[0].Asset);
      formObject = {
        ...formObject,
        [key]: value,
        EquipmentDescription: selectedDevice[0].AliasCode,
        Location: location.AssetCode,
        LocationDescription: location.Name
      };
      this.formHeader.current.setFieldsValue(formObject);
      this.setState({ formObject });
    }
    if (key === 'CreatedBy') {
      let selectedUser = usersList.filter((item) => item.Id === value);
      formObject = {
        ...formObject,
        [key]: value,
        Name: selectedUser[0]['UserName'],
        Phone: selectedUser[0]['MobileNumber'],
        Email: selectedUser[0]['Email']
      };
    } else if (
      key === 'TargetStart' ||
      key === 'TargetFinish' ||
      key === 'ActualStart' ||
      key === 'ActualFinish' ||
      key === 'RecordDate'
    ) {
      formObject = {
        ...formObject,
        [key]: moment(value).format('YYYY-MM-DD')
      };
    } else if (key === 'WorkTypeId') {
      formObject = {
        ...formObject,
        FrequencyId: '',
        Days: [],
        [key]: value
      };
    } else if (key === 'CreationTime') {
      formObject = {
        ...formObject,
        [key]: moment(value, 'HH:mm')
      };
    } else {
      formObject = {
        ...formObject,
        [key]: value
      };
    }
    this.formHeader.current && this.formHeader.current.setFieldsValue(formObject);
    this.setState({ [key]: value, formObject });
  };
  componentDidUpdate = async (prevProps) => {
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      this.formHeader.current.setFieldsValue(this.props.formObject);
    }
    if (prevProps.formObject !== this.props.formObject) {
      this.setState({
        formObject: this.props.formObject
      });
    }
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  };
  onReset = () => {
    this.formHeader.current.resetFields();
  };
  onFinish = async (values) => {
    let { formObject } = this.state;
    let userId =
      this.props.userProfile && this.props.userProfile.user && this.props.userProfile.user.Id
        ? this.props.userProfile.user.Id
        : '';
    let creationTime = moment(values.CreationTime).format('HH:mm:ss');
    let startDate = moment(values.EffectiveStartDate).format(`YYYY-MM-DD ${creationTime}`);
    let endDate = moment(values.EffectiveEndDate).format(`YYYY-MM-DD ${creationTime}`);
    let payload = {
      ...formObject,
      ...values,
      CreationTime: values.CreationTime
        ? moment.utc(values.CreationTime).format('YYYY-MM-DD HH:mm:ss')
        : undefined,
      EffectiveStartDate: values.EffectiveStartDate
        ? moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss')
        : undefined,
      EffectiveEndDate: values.EffectiveEndDate
        ? moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss')
        : undefined,
      ...(formObject && !formObject.Id ? { CreatedBy: userId } : {}),
      ...(formObject && formObject.Id ? { UpdatedBy: userId } : {})
    };
    let statusResponse = await submitScheduleInspection(payload);
    if (statusResponse && statusResponse.message) {
      message.success(statusResponse.message);
      this._getDataById(statusResponse.Id);
    } else if (statusResponse && statusResponse.Error) {
      message.error(statusResponse.Error);
    }
  };

  createWorkOrder = async () => {
    message.warn('Development inprogress');
  };

  fileupload = async (values, key) => {
    let { form } = this.state;
    form = {
      ...form,
      [key]: values
    };
    this.setState({ form });
  };

  getValueFromList = (list, compareKey, compareValue, resultKey) => {
    let filterObject = [];
    if (list && Array.isArray(list)) {
      filterObject = list.filter((listObject) => listObject[compareKey] === compareValue);
    }
    return filterObject && filterObject[0] && filterObject[0][resultKey]
      ? filterObject[0][resultKey]
      : '';
  };
  TableDropdownChange = async (value, modal) => {
    let { deviceList } = this.state;
    let formObject = this.formHeader.current.getFieldsValue();
    let selectedDevice = deviceList.filter((item) => item.AliasCode === value.AliasCode);
    let location = await getLocationByAsset(selectedDevice[0] && selectedDevice[0].Asset);

    formObject = {
      ...formObject,
      Equipment: value.AliasCode,
      EquipmentDescription: value.Name,
      DeviceCode: value.DeviceCode,
      DisplayCode: value.DisplayCode,
      Location: location && location[0] && location[0].AssetCode ? location[0].AssetCode : '',
      LocationDescription: location && location[0] && location[0].Name ? location[0].Name : ''
    };
    this.formHeader.current.setFieldsValue(formObject);
    this.setState({
      formObject,
      [modal]: false
    });
    this.getJobPlanList(value.AliasCode);
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, `Search`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translation(this.props.translationCache, `Search`)}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {translation(this.props.translationCache, `Reset`)}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  getDeviceData = (key) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `${API_DEVICE.LIST}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        this.setState({
          tableData: data,
          [key]: true,
          formObject: this.formHeader.current.getFieldsValue()
        });
      })
      .catch(function () {});
  };
  onModalCancel = (e) => {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please Click On Cancel');
    } else {
      this.setState({
        visible: false
      });
    }
  };
  onModalOk = () => {
    this.setState({
      visible: false
    });
  };
  jobPlanOnChange = async (value) => {
    let jobplanData = await getJobPlanById(value, 'withoutId');
    let formObject = this.formHeader.current.getFieldValue();
    formObject = {
      ...formObject,
      JobPlanDescription:
        jobplanData && jobplanData.JobPlanDescription ? jobplanData.JobPlanDescription : ''
    };
    this.formHeader.current.setFieldsValue(formObject);
    this.setState({
      formObject: {
        ...formObject,
        JobPlan: jobplanData,
        JobPlanDescription: jobplanData.JobPlanDescription
      },
      JobPlanName: value
    });
  };
  saveTableViewData = (InspectionData) => {
    let formObject = this.formHeader.current.getFieldsValue();
    formObject = {
      ...formObject,
      InspectionData
    };
    this.setState({ formObject, InspectionData });
    this.formHeader.current.setFieldsValue(formObject);
  };

  getJobPlanList = async (Equipment) => {
    let JobPlansList = await getJobPlansListByEquipment('Active', Equipment);
    this.setState({ JobPlansList: JobPlansList });
  };

  render() {
    let { translationCache } = this.props;
    let {
      sitesList,
      formObject,
      usersList,
      statusList,
      workTypesList,
      JobPlansList,
      days,
      frequencyList,
      frequencyObject
    } = this.state;

    let filterworkType =
      workTypesList &&
      Array.isArray(workTypesList) &&
      workTypesList.filter((item) => {
        return item.Value === this.formHeader.current.getFieldValue('WorkTypeId');
      });
    return (
      <StyledComponent
        theme={themeSettings}
        style={{ padding: '0px 15px', minHeight: window.innerHeight - 143 }}
      >
        <StyledForm
          name="SR"
          theme={themeSettings}
          ref={this.formHeader}
          onFinish={this.onFinish}
          initialValues={formObject}
          layout="horizontal"
          labelCol={{ flex: '130px' }}
          labelAlign="right"
          labelWrap
          wrapperCol={{ flex: 1 }}
        >
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <StyledButton style={{ marginLeft: '5px' }} theme={themeSettings} htmlType="submit">
              {translation(translationCache, constant.submit)}
            </StyledButton>
          </div>
          <StyledBox>
            {/* <StyledBoxLabel>
              {translation(translationCache, 'Service Request Details')}
            </StyledBoxLabel> */}

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item
                style={{
                  width: '100%',
                  flex: '50%',
                  marginTop: '50px'
                }}
                label={translation(translationCache, constant.Name)}
                name="Name"
                rules={[{ required: true, message: translation(translationCache, 'Input Name!') }]}
              >
                <InputComponent
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Name')}
                  value={formObject.Name}
                  onChange={(e) => this.handleChange(e.target.value, 'Name')}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%', marginTop: '50px' }}
                label={translation(translationCache, constant.description)}
                name="Description"
                rules={[
                  { required: true, message: translation(translationCache, 'Input Description!') }
                ]}
              >
                <InputComponent
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Input Description')}
                  onChange={(e) => this.handleChange(e.target.value, 'Description')}
                />
              </Form.Item>
              <Form.Item
                label={translation(translationCache, constant.workType)}
                name="WorkTypeId"
                style={{ width: '100%', flex: '50%' }}
                rules={[
                  { required: true, message: translation(translationCache, 'Select Work Type') }
                ]}
              >
                <Select
                  placeholder={translation(translationCache, 'Select Work Type')}
                  onChange={(value) => this.handleChange(value, 'WorkTypeId')}
                  disabled={formObject && formObject.Id ? true : false}
                  style={{ width: '70%' }}
                >
                  {workTypesList &&
                    Array.isArray(workTypesList) &&
                    workTypesList.map((workType, index) => {
                      return (
                        <Option key={index} value={workType.Value}>
                          {translation(translationCache, workType.Key)}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                style={{
                  width: '100%',
                  flex: '50%'
                }}
                label={translation(translationCache, constant.site)}
                name="SiteId"
                rules={[{ required: true, message: 'Select Site' }]}
              >
                <Select
                  placeholder={translation(translationCache, 'Select Site')}
                  onChange={(value) => this.handleChange(value, 'SiteId')}
                  style={{ width: '70%' }}
                  disabled
                >
                  {sitesList &&
                    Array.isArray(sitesList) &&
                    sitesList.map((site, index) => {
                      return (
                        <Option key={index} value={site.Id}>
                          {' '}
                          {site.Name}{' '}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.equipment)}
                name="DisplayCode"
                rules={[{ required: true, message: 'Select Equipment' }]}
              >
                <Input
                  disabled={true}
                  style={{
                    width: '70%',
                    flex: '50%'
                  }}
                  // disabled={formObject && formObject.Id ? true : false}
                  placeholder={translation(translationCache, 'Select Equipment')}
                  suffix={
                    <DownOutlined
                      style={{
                        color: formObject && formObject.Id ? null : '#1a3b5c',
                        cursor: formObject && formObject.Id ? 'not-allowed' : null,
                        opacity: formObject && formObject.Id ? '0.8' : null
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (formObject && !formObject.Id) {
                          this.getDeviceData('visible');
                        }
                      }}
                    />
                  }
                />
              </Form.Item>

              <Form.Item
                style={{
                  width: '100%',
                  flex: '50%'
                }}
                label={translation(translationCache, constant.equipmentDescription)}
                name="EquipmentDescription"
                rules={[
                  {
                    required: true,
                    message: translation(
                      translationCache,
                      'Autopopulate based on equipment selection'
                    )
                  }
                ]}
              >
                <InputComponent
                  style={{ width: '70%' }}
                  //maxLength={150}
                  disabled
                  placeholder={translation(
                    translationCache,
                    'Autopopulate based on equipment selection'
                  )}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.Location)}
                name="Location"
                rules={[
                  {
                    required: true,
                    message: translation(
                      translationCache,
                      'Autopopulate based on equipment selection'
                    )
                  }
                ]}
              >
                <InputComponent
                  style={{ width: '70%' }}
                  placeholder={translation(
                    translationCache,
                    'Autopopulate based on equipment selection'
                  )}
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                style={{
                  width: '100%',
                  flex: '50%'
                }}
                label={translation(translationCache, constant.locationDescription)}
                name="LocationDescription"
                rules={[
                  {
                    required: true,
                    message: translation(
                      translationCache,
                      'Autopopulate based on equipment selection'
                    )
                  }
                ]}
              >
                <InputComponent
                  style={{ width: '70%' }}
                  placeholder={translation(
                    translationCache,
                    'Autopopulate based on equipment selection'
                  )}
                  disabled={true}
                />
              </Form.Item>

              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.createdBy)}
                name="CreatedBy"
                rules={[{ required: false, message: 'Select Created By' }]}
              >
                <Select
                  style={{ width: '70%' }}
                  disabled={true}
                  placeholder={translation(translationCache, 'Created By')}
                  onChange={(value) => this.handleChange(value, 'CreatedBy')}
                >
                  {usersList &&
                    Array.isArray(usersList) &&
                    usersList &&
                    usersList.map((users, index) => {
                      return (
                        <Option key={index} value={users.Id}>
                          {' '}
                          {users.FirstName}{' '}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item name="Equipment"></Form.Item>

              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.CreatedDate)}
                name="CreatedDate"
                rules={[{ required: false, message: 'Created Date' }]}
              >
                <DatePicker
                  format={'YYYY-MM-DD HH:mm:ss'}
                  style={{ width: '70%' }}
                  disabled={true}
                  placeholder={translation(translationCache, 'Created Date')}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.status)}
                name="StatusId"
                rules={[{ required: true, message: 'Please Select Status' }]}
              >
                <Select
                  placeholder={translation(translationCache, 'Select Status')}
                  onChange={(value) => this.handleChange(value, 'StatusId')}
                  style={{ width: '70%' }}
                >
                  {statusList &&
                    Array.isArray(statusList) &&
                    statusList.map((status, index) => {
                      return (
                        <Option key={index} value={status.Value}>
                          {translation(translationCache, status.Key)}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item style={{ width: '100%', flex: '50%' }}>
                <InputComponent style={{ display: 'none' }} />
              </Form.Item>
            </div>
          </StyledBox>

          <>
            <StyledBox style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
              <StyledBoxLabel>
                {filterworkType && filterworkType[0] && filterworkType[0]['WorkType']
                  ? filterworkType[0]['WorkType']
                  : null}
              </StyledBoxLabel>
              <Form.Item
                style={{ width: '100%', flex: '50%', marginTop: '40px' }}
                label={translation(translationCache, constant.jobPlan)}
                name="JobPlanId"
                rules={[{ required: true }]}
              >
                <Select
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Select JobPlan')}
                  onChange={(value) => this.jobPlanOnChange(value)}
                >
                  {JobPlansList &&
                    Array.isArray(JobPlansList) &&
                    JobPlansList.map((jobplan, index) => {
                      return (
                        <Option key={index} value={jobplan.Id}>
                          {jobplan.JobPlanName}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%', marginTop: '40px' }}
                label={translation(translationCache, constant.jobplanDescription)}
                name="JobPlanDescription"
              >
                <InputComponent
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Enter JobPlanDescription')}
                />
              </Form.Item>
            </StyledBox>

            {this.formHeader.current &&
            this.formHeader.current.getFieldValue('JobPlanId') &&
            this.formHeader.current.getFieldValue('JobPlanId') !== undefined ? (
              <StyledBox style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                <StyledBoxLabel>{translation(translationCache, 'Schedule')}</StyledBoxLabel>

                <Form.Item
                  style={{ width: '100%', flex: '50%', marginTop: '40px' }}
                  label={translation(translationCache, constant.effectiveStartDate)}
                  name="EffectiveStartDate"
                >
                  <DatePicker
                    inputReadOnly={true}
                    style={{ width: '70%' }}
                    format="YYYY-MM-DD"
                    onChange={(value) => {
                      this.handleChange(value, 'EffectiveStartDate');
                    }}
                    disabledDate={(d) =>
                      !d ||
                      d.isAfter(
                        this.formHeader.current.getFieldValue() &&
                          this.formHeader.current.getFieldValue()['EffectiveEndDate']
                          ? moment(this.formHeader.current.getFieldValue()['EffectiveEndDate'])
                              .add(1, 'day')
                              .format('YYYY-MM-DD')
                          : ''
                      ) ||
                      d.isBefore(moment().add(1, 'day').format('YYYY-MM-DD'))
                    }
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%', marginTop: '40px' }}
                  label={translation(translationCache, constant.effectiveEndDate)}
                  name="EffectiveEndDate"
                >
                  <DatePicker
                    inputReadOnly={true}
                    style={{ width: '70%' }}
                    format="YYYY-MM-DD"
                    onChange={(value) => {
                      this.handleChange(value, 'EffectiveEndDate');
                    }}
                    disabledDate={(d) =>
                      !d ||
                      d.isBefore(
                        this.formHeader.current.getFieldValue() &&
                          this.formHeader.current.getFieldValue()['EffectiveStartDate']
                          ? moment(
                              this.formHeader.current.getFieldValue()['EffectiveStartDate']
                            ).format('YYYY-MM-DD')
                          : ''
                      ) ||
                      d.isBefore(moment().format('YYYY-MM-DD'))
                    }
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.frequency)}
                  name="FrequencyId"
                  rules={[{ required: true }]}
                >
                  <Select
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Select Frequency')}
                    onChange={(value) => this.handleChange(value, 'FrequencyId')}
                  >
                    {frequencyList &&
                      Array.isArray(frequencyList) &&
                      frequencyList.map((freq, index) => {
                        return (
                          <Option key={index} value={freq.Value}>
                            {translation(translationCache, freq.Key)}{' '}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                {formObject.Id &&
                this.formHeader &&
                frequencyObject &&
                this.formHeader.current &&
                this.formHeader.current !== null &&
                this.formHeader.current.getFieldValue('FrequencyId') ===
                  frequencyObject['Daily'] ? (
                  <>
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.workOrderCreationTime)}
                      name="CreationTime"
                      rules={[{ required: true }]}
                    >
                      <TimePicker
                        allowClear={false}
                        style={{ width: '70%' }}
                        format={'HH:mm'}
                        defaultOpenValue={moment('00:00', 'HH:mm')}
                        onChange={(value) => this.handleChange(value, 'CreationTime')}
                      />
                    </Form.Item>
                  </>
                ) : null}
                {formObject.Id &&
                this.formHeader &&
                frequencyObject &&
                this.formHeader.current &&
                this.formHeader.current !== null &&
                this.formHeader.current.getFieldValue('FrequencyId') ===
                  frequencyObject['Weekly'] ? (
                  <>
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.workOrderCreationTime)}
                      name="CreationTime"
                      rules={[{ required: true }]}
                    >
                      <TimePicker
                        allowClear={false}
                        style={{ width: '70%' }}
                        format={'HH:mm'}
                        onChange={(value) => this.handleChange(value, 'CreationTime')}
                      />
                    </Form.Item>
                  </>
                ) : null}
                {formObject.Id &&
                this.formHeader &&
                frequencyObject &&
                this.formHeader.current &&
                this.formHeader.current !== null &&
                this.formHeader.current.getFieldValue('FrequencyId') ===
                  frequencyObject['Monthly'] ? (
                  <>
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.workOrderCreationTime)}
                      name="CreationTime"
                      rules={[{ required: true }]}
                    >
                      <TimePicker
                        allowClear={false}
                        style={{ width: '70%' }}
                        format={'HH:mm'}
                        onChange={(value) => this.handleChange(value, 'CreationTime')}
                      />
                    </Form.Item>
                  </>
                ) : null}

                {!formObject.Id &&
                this.formHeader &&
                frequencyObject &&
                this.formHeader.current &&
                this.formHeader.current !== null &&
                this.formHeader.current.getFieldValue('FrequencyId') ===
                  frequencyObject['Daily'] ? (
                  <>
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.workOrderCreationTime)}
                      name="CreationTime"
                      rules={[{ required: true }]}
                    >
                      <TimePicker
                        allowClear={false}
                        style={{ width: '70%' }}
                        format={'HH:mm'}
                        defaultOpenValue={moment('00:00', 'HH:mm')}
                        onChange={(value) => this.handleChange(value, 'CreationTime')}
                      />
                    </Form.Item>
                  </>
                ) : null}
                {!formObject.Id &&
                this.formHeader &&
                frequencyObject &&
                this.formHeader.current &&
                this.formHeader.current !== null &&
                this.formHeader.current.getFieldValue('FrequencyId') ===
                  frequencyObject['Weekly'] ? (
                  <>
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.workOrderCreationTime)}
                      name="CreationTime"
                      rules={[{ required: true }]}
                    >
                      <TimePicker
                        allowClear={false}
                        style={{ width: '70%' }}
                        format={'HH:mm'}
                        onChange={(value) => this.handleChange(value, 'CreationTime')}
                      />
                    </Form.Item>
                  </>
                ) : null}
                {!formObject.Id &&
                this.formHeader &&
                frequencyObject &&
                this.formHeader.current &&
                this.formHeader.current !== null &&
                this.formHeader.current.getFieldValue('FrequencyId') ===
                  frequencyObject['Monthly'] ? (
                  <>
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.workOrderCreationTime)}
                      name="CreationTime"
                      rules={[{ required: true }]}
                    >
                      <TimePicker
                        allowClear={false}
                        style={{ width: '70%' }}
                        format={'HH:mm'}
                        onChange={(value) => this.handleChange(value, 'CreationTime')}
                      />
                    </Form.Item>
                  </>
                ) : null}
                {this.formHeader &&
                frequencyObject &&
                this.formHeader.current &&
                this.formHeader.current !== null &&
                this.formHeader.current.getFieldValue('FrequencyId') ===
                  frequencyObject['Weekly'] ? (
                  <>
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.selectDay)}
                      name="Days"
                      rules={[{ required: true }]}
                    >
                      <Select
                        style={{ width: '70%' }}
                        mode={'multiple'}
                        virtual={false}
                        showSearch
                        placeholder={translation(translationCache, 'Select Days')}
                        onChange={(value) => this.handleChange(value, 'Days')}
                      >
                        <Option value="0">{translation(translationCache, constant.sunday)}</Option>
                        <Option value="1">{translation(translationCache, constant.monday)}</Option>
                        <Option value="2">{translation(translationCache, constant.tuesday)}</Option>
                        <Option value="3">
                          {translation(translationCache, constant.wednesday)}
                        </Option>
                        <Option value="4">
                          {translation(translationCache, constant.thursday)}
                        </Option>
                        <Option value="5">{translation(translationCache, constant.friday)}</Option>
                        <Option value="6">
                          {translation(translationCache, constant.saturday)}
                        </Option>
                      </Select>
                    </Form.Item>
                  </>
                ) : null}
                {this.formHeader &&
                frequencyObject &&
                this.formHeader.current &&
                this.formHeader.current !== null &&
                this.formHeader.current.getFieldValue('FrequencyId') ===
                  frequencyObject['Daily'] ? (
                  <>
                    {/* <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.LeadTime)}
                      name="LeadTime"
                    >
                      <TimePicker
                        style={{ width: '70%' }}
                        format={'HH:mm'}
                        defaultOpenValue={moment('00:00', 'HH:mm')}
                        onChange={(value) => this.handleChange(value, 'LeadTime')} />
                    </Form.Item> */}
                  </>
                ) : null}
                {this.formHeader &&
                frequencyObject &&
                this.formHeader.current &&
                this.formHeader.current !== null &&
                this.formHeader.current.getFieldValue('FrequencyId') ===
                  frequencyObject['Weekly'] ? (
                  <>
                    {/* <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.LeadTime)}
                      name="LeadTime"
                      rules={[{ required: false }]}
                    >
                      <Select
                        style={{ width: '70%' }}
                        showSearch
                        placeholder={translation(translationCache, 'Select LeadWeek')}
                        onChange={(value) => this.handleChange(value, 'LeadTime')}
                      >
                        <Option value="1">{translation(translationCache, constant.sunday)}</Option>
                        <Option value="2">{translation(translationCache, constant.monday)}</Option>
                        <Option value="3">{translation(translationCache, constant.tuesday)}</Option>
                        <Option value="4">{translation(translationCache, constant.wednesday)}</Option>
                        <Option value="5">{translation(translationCache, constant.thursday)}</Option>
                        <Option value="6">{translation(translationCache, constant.friday)}</Option>
                        <Option value="7">{translation(translationCache, constant.saturday)}</Option>
                      </Select>
                    </Form.Item> */}
                  </>
                ) : null}
                {this.formHeader &&
                frequencyObject &&
                this.formHeader.current &&
                this.formHeader.current !== null &&
                this.formHeader.current.getFieldValue('FrequencyId') ===
                  frequencyObject['Monthly'] ? (
                  <>
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.selectDay)}
                      name="Days"
                      rules={[{ required: true }]}
                    >
                      <Select
                        // dropdownMatchSelectWidth={false}
                        virtual={false}
                        style={{ width: '70%' }}
                        mode={'multiple'}
                        showSearch
                        placeholder={translation(translationCache, 'Select Days')}
                        onChange={(value) => this.handleChange(value, 'Days')}
                      >
                        {' '}
                        {days &&
                          days.map((item) => {
                            return (
                              <Option key={item} value={item}>
                                {item}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                    {/* <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.LeadTime)}
                      name="LeadTime"
                      rules={[{ required: false }]}
                    >
                      <Select
                        style={{ width: '70%' }}
                        showSearch
                        placeholder={translation(translationCache, 'Select Lead Day')}
                        onChange={(value) => this.handleChange(value, 'LeadTime')}
                      >
                        {days &&
                          Array.isArray(days) &&
                          days.map((day) => {
                            return (
                              <Option key={day} value={day}>
                                {day}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item> */}
                  </>
                ) : null}

                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  name="Id"
                  rules={[{ required: false }]}
                ></Form.Item>
              </StyledBox>
            ) : null}
          </>

          <Form.Item
            style={{
              width: '100%',
              flex: '50%',
              marginTop: '50px'
            }}
            name="Id"
          ></Form.Item>
        </StyledForm>
        <StyledModal
          open={this.state.visible}
          width={800}
          closable
          title={translation(translationCache, constant.selectEquipment)}
          onOk={this.onModalOk}
          onCancel={this.onModalCancel}
        >
          <TableSelect
            data={this.state.tableData}
            translationCache={this.props.translationCache}
            key="Location"
            columns={[
              {
                title: translation(translationCache, 'Equipment'),
                dataIndex: 'DisplayCode',
                key: 'DisplayCode',
                ...this.getColumnSearchProps('DisplayCode')
              },
              {
                title: translation(translationCache, 'Equipment Description'),
                dataIndex: 'Name',
                key: 'Name',
                ...this.getColumnSearchProps('Name')
              }
            ]}
            handleDropdownChange={(e) => this.TableDropdownChange(e, 'visible')}
          />
        </StyledModal>
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(PMDetails);
